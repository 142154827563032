<template>
  <div class="container text-center bg-light">
    <div class="container_main py-3 mb-5 pb-5">

      <!-- <h1 v-if="isPWA" style="color:green;">PWA</h1>
      <h1 v-if="!isPWA" style="color:red;">not PWA</h1> -->

      <h1 class="mx-5 mb-4">
        <strong>Select</strong> an inhaler
      </h1>
      <p class="mx-5 mb-5">
        Try an inhaler that ressembles your own or select a different inhaler.
      </p>

      <!-- <device-card class="mx-5 mt-4"
      v-for="(device, index) in devices"
      :key="index"
      :device="device"
      :selected="selectedDevice && selectedDevice == device"
      @click.native="$store.dispatch('inhaler/setSelection', index)"
      ></device-card> -->

      <div v-for="group in deviceGroups" :key="group" class="device-group px-5 mt-4">
        <template v-if="filteredDevices(group).length > 1">
          <carousel :items="1" :loop="true" :dots="false" :nav="false" :margin="20"
            @changed="onChanged"
            ref="carousel"
            >
            <template slot="prev"><img src="images/arrow.svg"></template>
            <template slot="next"><img src="images/arrow.svg"></template>
            <device-card
            v-for="(device, index) in filteredDevices(group)"
            :data-device-id="getDeviceIndex(device)"
            :key="group+'_'+index"
            :device="device"
            @click.native="$store.dispatch('inhaler/setSelection', getDeviceIndex(device))"
            ></device-card>
          </carousel>
        </template>
        <template v-else>
          <device-card
          v-for="(device, index) in filteredDevices(group)"
          :key="group+'_'+index"
          :device="device"
          :selected="selectedDevice && selectedDevice == device"
          @click.native="$store.dispatch('inhaler/setSelection', getDeviceIndex(device))"
          ></device-card>
        </template>
      </div>
      

    </div>
    <div class="container_footer p-3 bg-light" :class="{collapsed: !selectedDevice}">
      <b-button variant="primary" @click="start()" class="d-block w-100 p-2">Get started</b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import carousel from 'vue-owl-carousel'
import _ from 'lodash'
import carousel from '~/components/Carousel.vue'
import DeviceCard from '~/components/DeviceCard.vue'
import { groups } from '~/data/devices.js'

export default {
  components: {
    DeviceCard,
    carousel,
  },
  
  data () {
    return {
      debouncedCarouselRefresh: () => {},
    }
  },

  computed: {
    ...mapGetters({
      devices: 'inhaler/devices',
      selectedDevice: 'inhaler/selectedDevice',
      isPWA: 'inhaler/isPWA',
    }),
    deviceGroups() {
      return groups
    },
  },

  mounted() {
    this.debouncedCarouselRefresh = _.debounce(this.carouselRefresh, 250)
  },

  watch: {
    selectedDevice() {
      // manually set active class to carousel item
      const items = document.querySelectorAll('.owl-item .device-card');
      items.forEach((item) => {
        item.classList.remove('selected')
      })

      const selectedItems = document.querySelectorAll('.owl-item .device-card[data-device-id="'+ this.getDeviceIndex(this.selectedDevice) +'"]')
      selectedItems.forEach((item) => {
        item.classList.add('selected')
      })
      
    },
  },

  methods: {
    async start() {
      // await this.$store.dispatch('inhaler/fetchAudioStream')
      this.$router.push('/start')
    },

    filteredDevices(name) {
      return this.devices.filter((device) => {
        return device.name == name
      })
    },

    getDeviceIndex(device) {
      let index = -1
      this.devices.forEach((item, i) => {
        if (item == device) {
          index = i
          return false
        }
      })
      return index
    },

    onChanged(event) {

      // check if event is not triggered by the following
      if (event.property.name != "settings") {
        this.debouncedCarouselRefresh()
      }
        
    },

    carouselRefresh() {
        this.$refs.carousel[0].trigger('refresh.owl.carousel')
    },

  },
}

</script>

<style scoped lang="scss">
  .container_footer {
    position: fixed;
    z-index: 2;
    bottom: 0;
    width: 100%;
    transition: bottom 0.35s cubic-bezier(.5,0,.5,1);

    &.collapsed {
      bottom: -5rem;
    }
  }

  .container {
    height: auto;
    overflow: hidden;
  }
  .container_main {
    height: auto;
    overflow: hidden;
  }
</style>

<style lang="scss">
  .device-group {
    position: relative;
    overflow: hidden;
    [id^=carousel_prev],
    [id^=carousel_next] {
      display: block !important;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 3.5rem;
      height: 3.5rem;
      padding: 1rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    [id^=carousel_prev] {
      left: 1.5rem;
      transform: translate(-50%, -50%) rotate(180deg);
    }
    [id^=carousel_next] {
      left: calc(100% - 1.5rem);
    }
  }
  .owl-stage-outer {
    overflow: visible !important;
  }
</style>