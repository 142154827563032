<template>
  <div class="device-card" :class="{selected:selected}">
    <img class="device-card_check" src="images/check.svg">
    <div class="device-card_content p-5">
      <div class="flex-grow-1">
        <img class="device-card_image" :src="'images/'+device.image">
      </div>
      <div>
        <div class="device-card_title">{{device.name}}</div>
        {{device.description}}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .device-card {
    position: relative; 
    background-color: white;
    border-radius: 1rem;
    // height: 20rem;
    &_check {
      pointer-events: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 1.75rem !important;
      height: 1.75rem;
      
      opacity: 0;
      transform: scale(0.5);
      transition: all 0.25s cubic-bezier(0,0.75,.25,1);
    }
    &_content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &_title {
      font-size: 3rem;
      font-weight: 600;
    }
    &_image {
      width: 6.5rem !important;
      height: 6.5rem;
      margin-bottom: 1rem;
      pointer-events: none;
    }

    &.selected {
      .device-card_check {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
</style>

<script>
export default {
  props: [
    'device',
    'selected',
  ],
}
</script>